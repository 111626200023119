<template>
  <div>
    <vs-row
      vs-justify="center"
      class="bg-white"
    >
      <vs-tabs v-model="selectedTab">
        <!-- Contact List Tab -->
        <vs-tab label="Lista de IDs">
          <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-xs="12"
          >
            <vs-card>
              <vs-table
                id="contact-table"
                ref="table"
                v-model="selected"
                class="text-nowrap"
                :max-items="10"
                :data="contacts"
                no-data-text="Nenhum registro - Faça uma nova busca"
                stripe
                pagination
                search
              >
                <!-- Actions -->
                <div slot="header">
                  <vs-button
                    v-if="fromFilter"
                    icon="undo"
                    color="primary"
                    type="border"
                    @click="goToFilters"
                  >
                    Voltar para filtros
                  </vs-button>

                  <vs-button
                    v-if="filter === 'S'"
                    icon="search"
                    color="primary"
                    class="ml-3"
                    @click="callSearchIdPopup"
                  >
                    Buscar ID
                  </vs-button>

                  <a
                    :href="!checkItemAccess('LGPD') ? '' : csvUrl"
                    :style="!checkItemAccess('LGPD') ? { pointerEvents: 'none', cursor: 'not-allowed' } : undefined"
                    rel="noopener noreferrer"
                    download
                  >
                    <vs-button
                      :disabled="!checkItemAccess('LGPD')"
                      icon="file_download"
                      :color="colorSecondary"
                      class="ml-3"
                    >
                      Exportar CSV
                    </vs-button>
                  </a>
                </div>

                <!-- Contact List -->
                <template slot-scope="{ data }">
                  <vs-tr
                    v-for="(person, pIdx) in data"
                    :key="pIdx"
                    :data="person"
                  >
                    <!-- ID Number -->
                    <vs-td :data="person.id">
                      <div>
                        <span
                          class="badge badge-light badge-pill border border-muted"
                        >
                          {{ person.identity }}
                        </span>
                      </div>
                    </vs-td>

                    <!-- Contact Info -->
                    <vs-td :data="person.nome">
                      <div class="d-flex flex-column">
                        <!-- Name -->
                        <h5 class="mb-0 font-16">
                          {{ capitalizeAllWords(person.nome) }}
                        </h5>

                        <!-- Email -->
                        <div
                          v-if="person.email"
                          class="contact-info-line"
                        >
                          <i class="mdi mdi-email mr-2" />

                          <span class="text-muted">
                            {{ person.email }}
                          </span>
                        </div>

                        <!-- Phone Number -->
                        <div
                          v-if="person.cel"
                          class="contact-info-line"
                        >
                          <i class="mdi mdi-cellphone mr-2" />

                          <span class="text-muted">
                            {{ person.cel }}
                          </span>
                        </div>
                      </div>
                    </vs-td>

                    <!-- Go To Fanview -->
                    <vs-td :data="person">
                      <div>
                        <vs-button
                          @click="
                            goToFanview(
                              person.identity,
                              person.nome,
                              person.cpf,
                            );
                          "
                        >
                          Ver detalhes
                        </vs-button>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-card>
          </vs-col>
        </vs-tab>

        <!-- Data Analysis Tab -->
        <vs-tab
          v-if="fromFilter"
          label="Análise de dados"
          @click="callWarningPopup"
        >
          <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-xs="12"
          >
            <vs-card>
              <h3>Análise de dados</h3>

              <div class="data-analysis--content">
                <!-- Categories / Indicator Groups -->
                <vs-collapse
                  class="indicator-groups"
                  accordion
                >
                  <vs-collapse-item
                    v-for="(grp, grpIdx) in indicatorGroups"
                    :key="grpIdx"
                    icon-arrow="add"
                  >
                    <div
                      slot="header"
                      class="font-weight-bold mb-2"
                    >
                      <i
                        :class="grp.icon"
                        class="mr-3"
                      />

                      {{ grp.group }}
                    </div>

                    <vs-card
                      v-for="(box, bIdx) in grp.boxes"
                      :key="bIdx"
                      class="cardx px-2 py-2 shadow"
                      icon-arrow="arrow_drop_up"
                    >
                      <div
                        slot="header"
                        class="mb-2 text-uppercase"
                      >
                        {{ box.title }}
                      </div>

                      <!-- Indicator Chips -->
                      <div
                        slot="media"
                        class="pl-1 pr-1"
                      >
                        <div
                          v-for="(indicator, indIdx) in box.indexes"
                          :key="indIdx"
                          class="col-md-12 col-sm-12"
                          @click="addIndicator(indicator)"
                        >
                          <vs-tooltip
                            v-if="!indicator.indId"
                            text="Indicador em manutenção"
                          >
                            <vs-chip
                              class="chips-margin"
                              color="#adaca6"
                            >
                              {{ indicator.idxText }}
                            </vs-chip>
                          </vs-tooltip>

                          <vs-chip
                            v-else
                            :key="chipRenderKey"
                            class="chips-margin"
                            :color="selectedIndicators.find((opt) => opt.indId === indicator.indId) ? colorSecondary : 'dark'"
                          >
                            {{ indicator.idxText }}
                          </vs-chip>
                        </div>
                      </div>
                    </vs-card>
                  </vs-collapse-item>
                </vs-collapse>

                <!-- Selected Indicators -->
                <div class="selected-indicators">
                  <h3 class="text-uppercase font-weight-bold">
                    Indicadores selecionados:
                  </h3>

                  <h4 v-if="!selectedIndicators || !selectedIndicators.length">
                    Nenhum indicador selecionado.
                  </h4>

                  <div
                    v-else
                    class="d-flex flex-wrap px-5 align-items-center"
                  >
                    <vs-chip
                      v-for="(selectedInd, siIdx) in selectedIndicators"
                      :key="siIdx"
                      class="w-auto"
                      :color="colorPrimary"
                      closable
                      @click="removeIndicator(selectedInd)"
                    >
                      {{ selectedInd.idxText }}{{ selectedInd.description }}
                    </vs-chip>
                  </div>

                  <vs-button
                    size="large"
                    @click="updateVisionIndicators"
                  >
                    Atualizar indicadores
                  </vs-button>
                </div>
              </div>
            </vs-card>
          </vs-col>
        </vs-tab>

        <!-- Results Tab -->
        <vs-tab
          v-if="fromFilter"
          label="Resultados"
          :style="{ backgroundColor: colorPrimary }"
          :disabled="visionLists.length < 1"
        >
          <vs-row vs-justify="flex-start">
            <vs-col
              v-for="(ls, lsIdx) in visionLists"
              :key="lsIdx"
              vs-lg="4"
              vs-xs="12"
              vs-sm="12"
            >
              <BarChart
                :key="ls.code"
                :data="ls.values"
                :title="ls.title"
              />
            </vs-col>
          </vs-row>
        </vs-tab>
      </vs-tabs>
    </vs-row>

    <!-- Create Filter Popup -->
    <vs-popup
      title="Análise de dados"
      :active.sync="createFilterPopup"
    >
      <p class="mb-4">
        <b>Para criar análises personalizadas do seu filtro, ele precisa primeiro estar gravado.</b>
      </p>

      <div class="text-center">
        <vs-button
          class="mx-auto"
          size="large"
          @click="goToFilters"
        >
          Gravar Filtro
        </vs-button>
      </div>
    </vs-popup>

    <!-- Id Search Popup -->
    <vs-popup
      title="Buscar ID"
      :active.sync="searchIdPopup"
    >
      <p>
        <b>Selecione o tipo de busca que deseja realizar:</b>
      </p>

      <select
        v-model="selectedSearchOptions"
        class="c-input"
        @change="changeSearchFocus"
      >
        <option
          v-for="(item, i) in searchOptions"
          :key="i"
          :value="item"
        >
          {{ item.text }}
        </option>
      </select>

      <vs-divider v-if="selectedSearchOptions.text" />

      <div v-if="selectedSearchOptions.text">
        <vs-input
          id="idSearchInput"
          v-model="idSearchText"
          class="w-100 mb-4"
          :label="selectedSearchOptions.text"
          :placeholder="selectedSearchOptions.ph"
        />

        <div class="text-center">
          <vs-button
            class="mx-auto"
            size="large"
            @click="searchForId()"
          >
            Buscar ID
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Components */
import BarChart from '../../dashboard-components/box-fanbase/BarChart.vue';

/* Helpers */
import capitalizeAllWords from '@/helpers/capitalizeAllWords';

/* Services */
import Fan from '@/services/fan';
import Filter from '@/services/filter';
import Survey from '@/services/survey';

const fanService = new Fan();
const filterService = new Filter();
const surveyService = new Survey();

export default {
  name: 'Contacts',
  components: { BarChart },
  props: {
    fromFilter: {
      type: Boolean,
      default: false,
    },
    filterSaved: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    /* Contacts */
    contacts: [],
    csvUrl: '',
    /* Filter */
    filter: 'S',
    filterType: '',
    idSearchText: '',
    searchOptions: [
      { value: 'nome', text: 'Nome', ph: 'Insira o nome' },
      { value: 'cpf', text: 'CPF', ph: 'Insira o CPF' },
      { value: 'email', text: 'Email', ph: 'Insira o email' },
    ],
    selectedSearchOptions: {},
    selected: [],
    /* Popups */
    createFilterPopup: false,
    searchIdPopup: false,
    /* BI */
    indicatorGroups: [],
    selectedIndicators: [],
    visionLists: [],
    chipRenderKey: 0,
    selectedTab: 0,
  }),
  computed: {
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    idToken() {
      return this.$store.state.accessToken.replace('tk=', '');
    },
    filterUid() {
      return this.$store.state.filterUid;
    },
    holdList() {
      return this.$store.state.holdList;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    projectId() {
      return parseInt(this.$store.state.selectedProject);
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },

  },
  created() {
    this.getCSVUrl();

    if (this.fromFilter && this.filterSaved) {
      this.getFilterType();

      // Get all indicator groups
      this.getIndicatorGroups();

      // Get indicators already added
      this.getVisionIndicators();

      // Get vision results
      this.getVisionLists();
    }

    if (this.holdList.length > 0) {
      this.contacts = this.holdList;

      this.$store.commit('RESET_HOLDLIST');
    } else if (this.fromFilter) {
      this.getContacts();
    }
  },
  methods: {
    /* API */
    getContacts() {
      this.$store.dispatch('set_isLoadingActive', true);

      fanService
        .getIdsRegister(
          this.projectId,
          this.filterUid,
          this.idToken,
        )
        .then((resp) => (this.contacts = resp.ids))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getCSVUrl() {
      this.csvUrl = fanService.getCSVUrl(this.ownerId, this.filterUid, this.idToken);
    },
    getFilterType() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService.getFilterVisions(this.ownerId)
        .then((resp) => {
          const filterSearch = resp.find((f) => f.uuid === this.filterUid);
          const type = filterSearch ? 'saved' : 'unsaved';

          this.filterType = type;
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getIndicatorGroups() {
      this.$store.dispatch('set_isLoadingActive', true);

      surveyService.getBoxesAsFilters(this.ownerId, this.token)
        .then((resp) => (this.indicatorGroups = resp))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getVisionIndicators() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService.getVisionIndicators(this.filterUid)
        .then((resp) => (this.selectedIndicators = resp))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getVisionLists() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService.getIndicatorValues(this.filterUid)
        .then((resp) => (this.visionLists = resp.listas))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    updateVisionIndicators() {
      // Create list of indicator id's
      const selectedIds = this.selectedIndicators.map((si) => si.indId);

      filterService.updateIndicatorsList(
        this.filterUid,
        selectedIds,
      )
        .then(() => {
          this.getVisionLists();

          if (this.visionLists.length > 1) {
            this.selectedTab = 3;
          }
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    searchForId() {
      this.contacts = [];
      this.searchIdPopup = false;

      const filter = `${this.selectedSearchOptions.value}=${this.idSearchText}`;

      this.$store.dispatch('set_isLoadingActive', true);

      fanService
        .getIdsSimpleFilter(
          this.ownerId,
          filter,
          this.idToken,
        )
        .then((resp) => (this.contacts = resp.ids))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Vision */
    addIndicator(indicator) {
      const found = this.selectedIndicators.find((si) => si.indId === indicator.indId);

      if (!found) {
        this.selectedIndicators.push(indicator);
      }
    },
    removeIndicator(indicatorId) {
      const updated = this.selectedIndicators.filter((si) => si.indId != indicatorId.indId);

      this.selectedIndicators = updated;

      this.chipRenderKey++;
    },
    /* Helpers */
    checkItemAccess(access) {
      if (!access) return true;

      return this.$store.state.loginData.accessValues.includes(access);
    },
    /* Popups */
    callSearchIdPopup() {
      this.selectedSearchOptions = {};
      this.searchIdPopup = true;
    },
    callWarningPopup() {
      if (this.filterType !== 'saved') {
        this.createFilterPopup = true;
      }
    },
    changeSearchFocus() {
      this.$nextTick(() => {
        document.getElementById('idSearchInput').focus()
      });
    },
    /* Router */
    goToFanview(id, name, document) {
      this.$store.commit('SET_HOLDLIST', this.contacts);

      this.$router.push({
        name: 'Fanview',
        params: {
          fanId: id,
          fanName: name,
          fromFilter: this.fromFilter,
          document: document || '',
        },
      });
    },
    goToFilters() {
      this.$router.push('/IDDigital/filtros');
    },
    /* Helpers */
    capitalizeAllWords(sentence) {
      if (sentence) {
        return capitalizeAllWords(sentence);
      }
    }
  },
};
</script>

<style scoped>
.vs-tabs--li {
  display: block;
  position: relative;
}

.data-analysis--content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 15px;
  min-height: 300px;
}

.selected-indicators {
  width: 500px;
  max-height: 400px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
}

.con-pagination-table.vs-table--pagination {
  justify-content: center;
  padding: 15px;
}

.con-slot-tabs {
  padding: 10px;
}

.con-vs-card {
  margin-bottom: 20px;
  border-radius: 4px;
  min-height: 200px;
}

.chipsMargin {
  margin-bottom: 5px;
}

.chipsMargin:hover {
  background-color: #cfcbcb;
  border-color: #cfcbcb;
}

.chipsRevert {
  background-color: blanchedalmond;
  color: #000;
}

.c-input {
  width: 33%;
  height: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 7px;
  transition: all 0.2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2b2b;
}
</style>
