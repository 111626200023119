/**
 * Capitalize all words in a case-diverse sentence.
 *
 * @param {string} sentence Any sequence of words.
 * @return {string} A formatted sentence.
 */
const capitalizeAllWords = (sentence) => {
  const trimmed = sentence.trim();

  const toLowercase = trimmed.toLowerCase();

  const words = toLowercase.split(' ');

  const result = [];

  // Capitalize each word in words array
  words.forEach((word) => {
    // Common Brazilian Portuguese name prepositions
    const prepositions = ['da', 'de', 'do', 'das', 'dos'];

    if (!prepositions.includes(word)) {
      const sliceFirst = word.slice(1);

      if (word[0]) {
        const firstLetterUp = word[0].toUpperCase();

        const capitalizedWord = firstLetterUp + sliceFirst;

        result.push(capitalizedWord);
      }
    } else {
      result.push(word);
    }
  });

  // Join words array with space to form a sentence
  return result.join(' ');
};

export default capitalizeAllWords;
